<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(../assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">FAQ’S</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                FAQ’S
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-3">
            <div class="card shadow-none bg-transparent">
              <h4
                class="card-header font-weight-bold bg-success rounded-top text-white"
              >
                問題分類
              </h4>
              <div class="card-body border border-top-0 rounded-bottom">
                <ul class="list-unstyled mb-0">
                  <li class="mb-2">
                    <router-link to="/faq/register"
                      class="font-weight-medium d-block border rounded py-2 pl-3"
                      >
                      會員註冊相關問題
                    </router-link>
                  </li>
                  <li class="my-2">
                    <router-link to="/faq/game-rule"
                      class="text-muted font-weight-medium d-block border rounded py-2 pl-3"
                      >
                    遊戲規則說明
                    </router-link>
                  </li>
                  <li class="my-2">
                    <router-link to="/faq/point-exchange"
                      class="text-muted font-weight-medium d-block border rounded py-2 pl-3"
                      >
                      點數及兌換機制說明
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-9 order-md-1">
            <div class="accordion" id="accordionOne">
              <div class="card">
                <div class="card-header bg-warning" id="headingOne">
                  <h5
                    class="icon-bg"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <span>如何註冊i玩錢會員？</span>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show shadow-sm rounded-sm"
                  aria-labelledby="headingOne"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <p class="mb-6">
                      在i玩錢網站右上角，點選「會員登入/註冊」即可開始註冊<br>
                      I玩錢提供多種不同註冊：<br>
                      （1）moneybar現有會員<br>
                      （2）Facebook<br>
                      （3）Google<br>
                      （4）Email
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-success" id="headingTwo">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span>忘記密碼怎麼辦？</span>
                  </h5>
                </div>

                <div
                  id="collapseTwo"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <p class="mb-6">
                      請在登入頁面中，點擊「忘記密碼」，系統會發一封信件至您的信箱中協助您重設密碼。<br>
                      <span class="text-primary"><strong>【提醒】沒有完成個人資料前，是無法登入進行財商分級以及金融智慧王遊戲測驗。</strong></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'register',
  mounted () {
    require('@/assets/js/base.js')
  }
}
</script>
